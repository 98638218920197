@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~react-toastify/dist/ReactToastify.css';

@import url('http://fonts.cdnfonts.com/css/montserrat');

* {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: inherit;
  line-height: inherit;
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  @apply max-w-full;
}

html {
  @apply text-sm scroll-smooth h-full;
  body {
    @apply h-full bg-black dark:bg-background-dark text-body-primary;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    p {
      @apply leading-6;
    }
  }

  .blank-page {
    .content {
      @apply ml-0;
      &.app-content {
        @apply overflow-y-auto overflow-x-hidden p-0 transition-none;
        .header-navbar-shadow {
          display: none;
        }
      }
    }
    .content-wrapper {
      @apply p-0;
    }
  }
}

.main_header::before {
  content: '';
  flex: 1 1;
}

.hero {
  &_container {
    @apply bg-top bg-no-repeat overflow-hidden h-full;
    background-image: url(https://showmax.akamaized.net/e/welcome_pages/welcome_2020/ng/welcome/festive_hero_bg-glow.png);

    @media (min-width: 993px) {
      @apply bg-cover relative flex flex-row-reverse justify-between;
      min-height: 85vh;
    }
  }

  &_image {
    @apply -my-5 mx-0;

    @media (min-width: 577px) {
      @apply py-0 px-3.5 m-0;
    }

    @media (min-width: 993px) {
      @apply flex-shrink-0 p-0;
      max-width: 60%;

      img {
        @apply w-full h-full object-contain;
        max-height: 78vh;
      }
    }
  }

  &_body {
    @apply flex flex-col items-center relative text-center px-3.5 pb-12;

    @media (min-width: 993px) {
      @apply justify-center items-start py-8 pr-0 text-left;
      padding-left: 4.6vw;
    }
  }

  &_title {
    @apply mb-5 text-[clamp(18px,6vw,38px)] font-extrabold text-white;

    @media (min-width: 993px) {
      @apply mb-5;
      font-size: 2.4vw;
    }
  }

  &_subtitle {
    @apply font-normal text-white text-base;
    max-width: 600px;

    @media (min-width: 993px) {
      @apply mb-5 text-base;
    }
  }
}

.auth-image {
  @apply -my-5 mx-0;

  @media (min-width: 577px) {
    @apply py-0 px-3.5 m-0;
  }

  @media (min-width: 993px) {
    @apply flex-shrink-0 p-0 w-full;
    // max-width: 60%;

    img {
      @apply w-full h-full object-contain;
      max-height: 78vh;
    }
  }
}

.subscribe-form {
  @media (max-width: 768px) {
    @apply px-3 flex justify-center items-center min-h-[calc(100vh-96px)] w-full;
  }
}

.form-overlay {
  @media (max-width: 768px) {
    @apply fixed inset-0 bg-black bg-opacity-60;
  }
}

.modal-body {
  @media (max-width: 768px) {
    @apply px-3 flex justify-center items-center min-h-[calc(100vh-96px)] w-full;
    background-image: url(https://res.cloudinary.com/matec-technology-services/image/upload/v1671547818/showmax/WhatsApp_Image_2022-12-20_at_15.39.47_moyabx.jpg);
    background-position: center;
    background-repeat: no-repeat;
  }
}

// ** forms
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f6f8fb inset !important;
  -webkit-text-fill-color: #333333 !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form-group {
  @apply mb-4;
}

label {
  @apply mb-2 text-body-primary text-sm inline-block text-left;
}

// ** form control
.form-control {
  @apply block w-full h-11 text-base font-normal leading-6 text-body-primary
  transition-[border-color,_box-shadow] duration-150
  ease-in-out focus:text-body-primary focus:outline-none motion-reduce:transition-none
  placeholder:text-body-tertiary placeholder:text-sm placeholder:opacity-100 focus:placeholder:transform focus:placeholder:translate-x-3 focus:placeholder:transition-all py-6 px-4
  bg-white bg-clip-padding border border-solid border-gray-300 rounded-full
  focus:bg-white focus:border-primary;

  &:focus {
    box-shadow: 0 3px 10px 0 rgba(14, 96, 226, 0.1);
  }
  &:valid:focus {
    box-shadow: 0 3px 10px 0 rgba(14, 96, 226, 0.1);
  }

  &:not(:focus)::placeholder {
    @apply transform translate-x-0 transition-all;
  }

  &::-ms-expand {
    @apply bg-transparent border-0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #333333;
  }

  &:disabled,
  &:read-only {
    @apply bg-[#efefef] opacity-100;
  }
}

// ** input group
.input-group {
  @apply relative flex flex-wrap items-stretch w-full;

  > .form-control,
  > .form-control-plaintext {
    @apply min-w-0 mb-0 relative flex-1;
    width: 1%;
  }

  > .form-control:not(:last-child),
  > .custom-select:not(:last-child) {
    @apply rounded-r-none;
  }

  > .form-control:not(:first-child),
  > .custom-select:not(:first-child) {
    @apply rounded-l-none;
  }

  &:focus-within {
    .form-control,
    .input-group-text,
    .btn {
      @apply border-primary shadow-none;
    }
  }

  &.is-valid {
    .input-group-text {
      @apply border-success;
    }
    &:not(.bootstrap-touchspin):focus-within {
      .input-group-text {
        @apply border-success;
      }
    }
  }

  &:disabled {
    .input-group-text {
      @apply bg-[#efefef] opacity-100;
    }
  }

  > .input-group-append > .btn,
  > .input-group-append > .input-group-text,
  > .input-group-prepend:not(:first-child) > .btn,
  > .input-group-prepend:not(:first-child) > .input-group-text,
  > .input-group-prepend:first-child > .btn:not(:first-child),
  > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    @apply rounded-l-none;
  }

  > .input-group-prepend > .btn,
  > .input-group-prepend > .input-group-text,
  > .input-group-append:not(:last-child) > .btn,
  > .input-group-append:not(:last-child) > .input-group-text,
  > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  > .input-group-append:last-child > .input-group-text:not(:last-child) {
    @apply rounded-r-none;
  }
}

.input-group-prepend,
.input-group-append {
  @apply flex;
}

.input-group-prepend {
  margin-right: -1px;
  .input-group-text {
    @apply border-r-0;
  }
}

.input-group-append {
  margin-left: -1px;
  .input-group-text {
    @apply border-l-0;
  }
  &:not(:last-child) .input-group-text {
    @apply border-r-0;
  }
}

.input-group-text {
  @apply px-3 mb-0 text-center bg-white border border-solid
border-[#d8d6de] rounded-lg transition-[border-color,_box-shadow]
duration-150 ease-in-out flex items-center
text-base font-normal leading-6 whitespace-nowrap;
}

// input group merge
.input-group-merge {
  .form-control {
    &:not(:first-child) {
      @apply pl-0 border-l-0;
    }

    &:not(:last-child) {
      @apply pr-0 border-r-0;
    }

    &.is-valid {
      ~ .input-group-append {
        .input-group-text {
          @apply border-success;
        }
      }
    }
  }
  .input-group-prepend {
    ~ .form-control.is-valid {
      .input-group-text & {
        @apply border-success;
      }
    }
  }
}

// toast
.toast {
  &.show {
    @apply z-50;
  }
  .close:focus {
    @apply outline-none;
  }
}

.Toastify__toast {
  @apply bg-white rounded-sm shadow-md p-3;
  .Toastify__toast-body,
  .Toastify__close-button {
    @apply text-gray-500 bg-transparent;
  }
  .toastify-header {
    @apply flex justify-between items-center pb-2;
    .title-wrapper {
      @apply flex items-center;
      .avatar svg {
        @apply h-3 w-3;
      }
    }
    .toast-title {
      @apply text-body-primary font-semibold ml-3 mb-0;
    }
  }
  .toastify-body {
    @apply text-gray-500 pl-9 text-xs;
  }
  .Toastify__close-button {
    @apply opacity-100 mt-0.5 ml-2;
    svg {
      @apply h-3 w-3 text-body-primary;
    }
    &:hover svg {
      @apply text-body-primary;
    }
    &:focus {
      @apply outline-none;
    }
  }
  &.Toastify__toast- {
    .toast-title {
      @apply text-primary;
    }
  }
  &.Toastify__toast--error {
    .toast-title {
      @apply text-red-500;
    }
    .Toastify__progress-bar {
      @apply bg-red-500;
    }
  }
}
